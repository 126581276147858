import { Component, OnInit, Inject, Renderer, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';

import { LocationStrategy, PlatformLocation, Location, DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-main',
    templateUrl: './main.html',
})
export class MainComponent implements OnInit {

    constructor( ) {}

    ngOnInit() {
        
    }
}
